import React from 'react';
import cls from 'classnames';

import { Typography } from '../Typography';
import { type TPAComponentProps } from '../types';

import classes from './ToggleButton.scss';

export interface IToggleButtonProps
  extends React.HTMLAttributes<HTMLButtonElement>,
    TPAComponentProps {
  selected?: boolean;
}

export const ToggleButton = React.forwardRef<
  HTMLButtonElement,
  IToggleButtonProps
>((props, ref) => {
  const { className, selected, children, ...rest } = props;

  return (
    <button
      ref={ref}
      aria-pressed={selected}
      className={cls(classes.root, className, {
        [classes.selected]: selected,
      })}
      {...rest}
    >
      <Typography variant="p2-12" className={classes.label}>
        {children}
      </Typography>
    </button>
  );
});

ToggleButton.displayName = 'wui/ToggleButton';
